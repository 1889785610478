<template>
  <Card v-if="collection">
    <div class="text-lg font-semibold text-heading">{{ collection.name }}</div>

    <div class="mt-12">
      <div class="text-7xl text-heading">{{ acceptancePercentage }}%</div>
      <div class="mt-1 text-lg text-supplement">acceptance rate</div>
      <div class="mt-4">
        <div>
          {{ collection.acceptances }} of your {{ total_advisors_count }} connected advisors have accepted this
          collection.
        </div>
        <div class="mt-10 flex items-center space-x-8">
          <Button :href="collection['signed-urls'].accepted" as="a" prepend-icon="download" type="primary"
            >Download acceptances list
          </Button>
          <a :href="collection['signed-urls']['not-accepted']" class="flex items-center space-x-3">
            <Icon icon="download" />
            <span>Download list of advisors who have yet to accept</span>
          </a>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title }
  },
  computed: {
    acceptancePercentage() {
      return Math.round((this.collection.acceptances / this.total_advisors_count) * 100)
    }
  },
  data: () => ({
    title: null,
    collection: null,
    total_advisors_count: null
  }),
  async created() {
    const response = await this.$api.get(`analytics/trade/extended-collections/${this.$route.params.id}`)
    this.collection = response.data.collection
    this.total_advisors_count = response.data.total_advisors_count
    this.title = `${this.collection.name} Analytics`
  }
}
</script>
